import React, { useEffect } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

import Img from "gatsby-image";
import SEO from "../components/SEO";
import Layout from "../components/Layout";

import HeroImage from "../components/HeroImage/HeroImage";
import heroBox from "../images/hepatitis-b-risk/hero-box.svg";

import heroBoxWithoutAnimation from "../images/hepatitis-b-risk/hero-box-without-animation.svg";

import Slab from "../components/Slab";
import Subheading from "../components/Subheading/Subheading";
import DonutGraph from "../components/DonutGraph/DonutGraph";
import Strong from "../components/Strong";

import oneInTwelveText from "../images/hepatitis-b-risk/2-0-1-in-12.svg";
import oneInTenText from "../images/hepatitis-b-risk/2-0-1-in-10.svg";
import { ReactComponent as OneInTwelveDesktop } from "../images/hepatitis-b-risk/one-in-twelve-people-desktop.svg";
import { ReactComponent as OneInTwelveMobile } from "../images/hepatitis-b-risk/one-in-twelve-people-mobile.svg";
import { ReactComponent as OneInTenDesktop } from "../images/hepatitis-b-risk/one-in-ten-people-desktop.svg";
import { ReactComponent as OneInTenMobile } from "../images/hepatitis-b-risk/one-in-ten-people-mobile.svg";
import { ReactComponent as InfoIcon } from "../images/icons/info.svg";
import References from "../components/References/References";
import ButtonSecondary from "../components/Buttons/ButtonSecondary";
import ShadedList, {
  ShadedListItem,
} from "../components/ShadedList/ShadedList";
import NextPage from "../components/Link/NextPage";
import AssetCardGroup from "../components/AssetCard/AssetCardGroup";
import ReferenceItem from "../components/References/ReferenceItem";
import ScreeningGuidelines from "../partials/AssetCards/ScreeningGuidelines";
import AsianInfographic from "../partials/AssetCards/AsianInfographic";
import AfricanInfographic from "../partials/AssetCards/AfricanInfographic";
import Paragraph from "../components/Paragraph";
import Footnotes from "../components/Footnotes";
import Abbr from "../components/Abbr/Abbr";
import AbbrList from "../components/Abbr/AbbrList";
import tracker from "../tracker";
import ImageWrapper from "../components/ImageWrapper";
import Parallax from "../components/Parallax/Parallax";

const WhoIsAtRiskPage = () => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "hepatitis-b-risk/hero.jpg" }) {
        ...HeroQuery
      }
      slab1People: file(
        relativePath: { eq: "hepatitis-b-risk/2-0-slab1-people.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 440) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      slab2People: file(
        relativePath: { eq: "hepatitis-b-risk/2-0-slab2-people.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      page: trackingJson(id: { eq: "hepatitis-b-risk" }) {
        subject
      }
      howToScreenAtRisk: trackingJson(id: { eq: "howToScreenAtRisk" }) {
        ...TrackingQuery
      }
      whyScreen: trackingJson(id: { eq: "whyScreen" }) {
        ...TrackingQuery
      }
      downloadTheGuide: trackingJson(id: { eq: "downloadTheGuide" }) {
        ...TrackingQuery
      }
      viewInfographicAsian: trackingJson(id: { eq: "viewInfographicAsian" }) {
        ...TrackingQuery
      }
      viewInfographicAfrican: trackingJson(
        id: { eq: "viewInfographicAfrican" }
      ) {
        ...TrackingQuery
      }
    }
  `);
  useEffect(() => {
    window.dataLayer.push(data.page);
  }, []);

  return (
    <Layout>
      <SEO
        title="Hepatitis B At Risk Patients"
        description="Learn about patients who are most at-risk for chronic hep B, especially those from high prevalence regions like Asia and Africa."
      />
      <HeroImage
        headLine="Who is at risk for chronic hepatitis&nbsp;B?"
        content={
          <span>
            ~2.2 million people in the US have chronic hepatitis B (CHB).
            <sup>1,2</sup> Up to 95% of people in the US who have CHB emigrated
            from countries with intermediate and high endemicity<sup>3</sup> of
            hepatitis B virus (HBV)—including certain Asian and African
            countries.
          </span>
        }
        fluidQuery={data.hero.childImageSharp.fluid}
        boxSrc={heroBox}
        boxSrcWithoutAnimation={heroBoxWithoutAnimation}
        imagePosition={{
          desktop: {
            x: 60,
            y: 75,
          },
          mobile: {
            x: 66,
            y: 50,
          },
        }}
      />
      <Slab className="bg-grey">
        <Subheading className="w-full">
          In the US, Asians have the highest prevalence of HBV infection
          <sup>4,a</sup>
        </Subheading>
        <div className="relative">
          <div className="w-full lg:w-1/2">
            <Paragraph className="py-4 uppercase text-center md:text-left">
              Asian Americans and Pacific Islanders make up <Strong>5%</Strong>{" "}
              of the US population, yet:
            </Paragraph>
            <div className="flex flex-col md:flex-row items-center">
              <DonutGraph
                percent={0.5}
                prefix="&gt;"
                className="w-full px-24 md:px-2 py-2 md:w-1/4 md:-ml-4"
                alt="Donut graph showing 50%"
                maxWidth={135}
              />
              <Strong
                color="red"
                size="xl"
                className="text-center py-2 md:text-left md:w-3/4"
              >
                more than 50% of people in the US with CHB are Asian Americans
                and Pacific Islanders<sup>5</sup>
              </Strong>
            </div>
          </div>
          <div className="w-full bg-white rounded-lg py-4 px-8 flex items-center flex-col md:flex-row relative z-10 mt-16">
            <div className="w-full md:w-1/2 lg:w-2/5">
              <div className="flex justify-start items-center">
                <img
                  src={oneInTwelveText}
                  alt="1 in 12 Asian Americans"
                  className="md:pr-4"
                />
              </div>
              <div className="uppercase text-grey-dark pb-4 md:pb-0">
                Approximately 1 in 12 Asian Americans have CHB<sup>5</sup>
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-3/5 text-red-light flex justify-start md:justify-around flex-wrap lg:flex-no-wrap">
              <OneInTwelveDesktop className="hidden lg:block" />
              <OneInTwelveMobile className="block lg:hidden" />
            </div>
          </div>
          <Footnotes>
            <Abbr term="CHB" title="chronic hepatitis B." />
          </Footnotes>
          <Footnotes>
            <sup>a</sup>As compared with other ethnic groups and the total
            population in the US.<sup>4</sup>
          </Footnotes>
          <div className="relative z-10 py-4"></div>
          <div className="lg:absolute lg:bottom-0 lg:right-0 lg:w-1/2 -mb-32 lg:-mb-8 z-0 px-12 md:px-24 lg:px-0">
            <Parallax speed={-1} percentage={0.2}>
              <ImageWrapper maxWidth={440}>
                <Img fluid={data.slab1People.childImageSharp.fluid} />
              </ImageWrapper>
            </Parallax>
          </div>
        </div>
      </Slab>
      <Slab className="bg-grey-medium relative">
        <div className="relative">
          <div className="md:flex items-end">
            <div className="w-full md:w-1/2 hidden md:block">
              <ImageWrapper maxWidth={350}>
                <Img fluid={data.slab2People.childImageSharp.fluid} />
              </ImageWrapper>
            </div>
            <div className="w-full md:w-1/2">
              <Subheading className="w-full">
                African-born people in the US have a higher prevalence of CHB
                <sup>2,b</sup>
              </Subheading>
              <Paragraph className="mb-8">
                Almost <Strong className="text-grey-dark">200,000</Strong> men
                and women with CHB in America emigrated from Africa.
                <sup>6,c</sup>
              </Paragraph>
            </div>
          </div>
          <div className="w-full bg-white rounded-lg py-4 px-8 flex items-center flex-col md:flex-row relative z-10">
            <div className="w-full md:w-1/2">
              <div className="flex justify-start items-center">
                <img src={oneInTenText} alt="~1 in 10 African-born" />
              </div>
              <div className="uppercase text-grey-dark pb-4 md:pb-0">
                Up to 1 in 10 African-born people in the US have CHB
                <sup>2</sup>
              </div>
            </div>
            <div className="w-full md:w-1/2 text-red-light flex justify-start md:justify-around flex-wrap lg:flex-no-wrap">
              <OneInTenDesktop className="hidden lg:block" />
              <OneInTenMobile className="block lg:hidden" />
            </div>
          </div>
          <Footnotes>
            <div>
              <sup>b</sup>As compared with other foreign-born people in the US.
            </div>
            <div>
              <sup>c</sup>2008 estimate.<sup>6</sup>
            </div>
          </Footnotes>
          <div className="md:hidden -mb-10 z-0">
            <ImageWrapper maxWidth={350}>
              <Img fluid={data.slab2People.childImageSharp.fluid} />
            </ImageWrapper>
          </div>
        </div>
      </Slab>
      <Slab className="bg-green relative">
        <InfoIcon />
        <Strong color="white" size="lg" className="py-4 block">
          At-risk patients should be screened for HBV infection to identify
          chronically infected people who may benefit from treatment and
          appropriate follow-up care.<sup>3</sup>
        </Strong>
        <ButtonSecondary
          to="/hepatitis-b-screening-diagnosis"
          tracking={tracker(data.howToScreenAtRisk)}
        >
          How to Screen At-risk Patients
        </ButtonSecondary>
        <div className="h-4" />
      </Slab>
      <Slab>
        <Subheading>Groups at risk for HBV infection</Subheading>
        <Paragraph>
          <Strong color="grey-dark">
            The AASLD, ACP and CDC, and USPSTF identify the following groups at
            risk for HBV infection:
          </Strong>
        </Paragraph>
        <div className="flex flex-col md:flex-row md:-mx-4 py-8">
          <div className="w-full md:w-1/2 md:px-4">
            <ShadedList>
              <ShadedListItem>
                <Strong>People born in regions</Strong> where the prevalence of
                HBV infection is &ge;2%<sup>3,7-9</sup>
              </ShadedListItem>
              <ShadedListItem>
                <Strong>US-born people not vaccinated</Strong> as infants and
                whose parents were born in regions having an HBV prevalence of
                &ge;8%<sup>3,8,9</sup>
              </ShadedListItem>
              <ShadedListItem>
                <Strong>Household and sexual contacts</Strong> of persons with
                HBV infection<sup>3,7-9</sup>
              </ShadedListItem>
              <ShadedListItem>
                <Strong>
                  All pregnant women<sup>8-10</sup>
                </Strong>
              </ShadedListItem>
              <ShadedListItem>
                <Strong>
                  Men who have sex with men<sup>3,7-9</sup>
                </Strong>
              </ShadedListItem>
              <ShadedListItem>
                <Strong>
                  People who inject drugs<sup>3,7-9</sup>
                </Strong>
              </ShadedListItem>
            </ShadedList>
          </div>
          <div className="w-full md:w-1/2 md:px-4">
            <ShadedList>
              <ShadedListItem>
                <Strong>
                  Individuals infected with HCV or HIV<sup>3,7-9</sup>
                </Strong>
              </ShadedListItem>
              <ShadedListItem>
                <Strong>
                  People with certain medical conditions<sup>3,7-9</sup>
                </Strong>
                <ul className="list-dash">
                  <li>Needing immunosuppressive therapy</li>
                  <li>Undergoing hemodialysis</li>
                </ul>
              </ShadedListItem>
              <ShadedListItem>
                <Strong>People with elevated ALT or AST</Strong> of unknown
                etiology<sup>8,9</sup>
              </ShadedListItem>
              <ShadedListItem>
                <Strong>
                  Donors of blood/organ/tissue<sup>8,9</sup>
                </Strong>
              </ShadedListItem>
              <ShadedListItem>
                <Strong>
                  Healthcare providers
                  <sup>8,9</sup>
                </Strong>
              </ShadedListItem>
              <ShadedListItem>
                <Strong>
                  People exposed to bodily fluids
                  <sup>8,9</sup>
                </Strong>
              </ShadedListItem>
              <ShadedListItem>
                <Strong>
                  People who are incarcerated
                  <sup>8</sup>
                </Strong>
              </ShadedListItem>
            </ShadedList>
          </div>
        </div>
        <AbbrList>
          <Abbr
            term="AASLD"
            title="American Association for the Study of Liver Diseases"
          />
          <Abbr term="ACP" title="American College of Physicians" />
          <Abbr term="ALT" title="alanine aminotransferase" />
          <Abbr term="AST" title="aspartate aminotransferase" />
          <Abbr term="CDC" title="Centers for Disease Control and Prevention" />
          <Abbr term="HCV" title="hepatitis C virus" />
          <Abbr term="HIV" title="human immunodeficiency virus" />
          <Abbr term="USPSTF" title="U.S. Preventive Services Task Force" />
        </AbbrList>
      </Slab>
      <Slab>
        <NextPage
          to="/hepatitis-b-risk/screening"
          tracking={tracker(data.whyScreen)}
        >
          Why screen your patients for HBV?
        </NextPage>
        <Subheading level={3} pad>
          Information for healthcare professionals
        </Subheading>
        <div className="h-4"></div>
        <AssetCardGroup>
          <ScreeningGuidelines tracking={tracker(data.downloadTheGuide)} />
          <AsianInfographic
            cta="View Infographic"
            tracking={tracker(data.viewInfographicAsian)}
          />
          <AfricanInfographic
            cta="View Infographic"
            tracking={tracker(data.viewInfographicAfrican)}
          />
        </AssetCardGroup>
        <References className="mb-8">
          <ReferenceItem>
            Gish R, Cohen C, Block J, et al. Data supporting updating estimates
            of the prevalence of chronic hepatitis B and C in the United States.{" "}
            <em>Hepatology</em>. 2015;62:1339-1341.
          </ReferenceItem>
          <ReferenceItem>
            Kowdley K, Wang C, Welch S, et al. Prevalence of chronic hepatitis B
            among foreign-born persons living in the United States by country of
            origin. <em>Hepatology</em>. 2012;56:422-433.
          </ReferenceItem>
          <ReferenceItem>
            LeFevre ML, on behalf of the United States Preventive Services Task
            Force. Screening for hepatitis B virus infection in nonpregnant
            adolescents and adults: U.S. Preventive Services Task Force
            recommendation statement. <em>Ann Intern Med</em>.
            2014;161(1):58-66.
          </ReferenceItem>
          <ReferenceItem>
            Kim H, Rotundo L, Yang J, et al. Racial/ethnic disparities in the
            prevalence and awareness of Hepatitis B virus infection and immunity
            in the United States. <em>J Viral Hepat</em>. 2017;24:1052-1066.
          </ReferenceItem>
          <ReferenceItem>
            Centers for Disease Control and Prevention. Asian Americans and
            Pacific Islanders and chronic hepatitis B. CDC website.
            https://www.cdc.gov/hepatitis/populations/api.htm. Last reviewed
            August 27, 2019. Accessed October 15, 2019.
          </ReferenceItem>
          <ReferenceItem>
            Kim WR. Epidemiology of hepatitis B in the United States.{" "}
            <em>Hepatology</em>. 2009;49:S28-S34.
          </ReferenceItem>
          <ReferenceItem>
            Rajbhandari R, Chung RT. Screening for hepatitis B virus infection:
            a public health imperative. <em>Ann Intern Med</em>.
            2014;161(1):76-77.
          </ReferenceItem>
          <ReferenceItem>
            Abara W, Qaseem A, Schillie S, et al. Hepatitis B vaccination,
            screening, and linkage to care: best practice advice from the
            American College of Physicians and the Centers for Disease Control
            and Prevention. <em>Ann Intern Med</em>. 2017;167(11):794-804.
          </ReferenceItem>
          <ReferenceItem>
            Terrault N, Lok A, McMahon B, et al. Update on prevention,
            diagnosis, and treatment of chronic hepatitis B: AASLD 2018
            hepatitis B guidance. <em>Hepatology</em>. 2018;67(4):1560-1599.
          </ReferenceItem>
          <ReferenceItem>
            U.S. Preventive Services Task Force. Screening for hepatitis B virus
            infection in pregnancy: U.S. Preventive Services Task Force
            reaffirmation recommendation statement. <em>Ann Intern Med</em>.
            2009;150(12):869-873.
          </ReferenceItem>
        </References>
      </Slab>
    </Layout>
  );
};

export default WhoIsAtRiskPage;
