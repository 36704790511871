import React, { FunctionComponent } from "react";
import { ReactComponent as ArrowIcon } from "./arrow-right.svg";
import { Link } from "gatsby";
type Props = {
  to: string;
  tracking: Function;
};
const NextPage: FunctionComponent<Props> = ({ children, to, tracking }) => {
  return (
    <div className="text-right mb-8 mr-4">
      <Link to={to} onClick={tracking}>
        <span className="font-bold antialiased text-green underline mr-2">
          {children}
        </span>
        <ArrowIcon className="inline-block text-green stroke-current" />
      </Link>
    </div>
  );
};
export default NextPage;
