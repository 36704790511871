import React, { FunctionComponent } from "react";

import "./ShadedList.scss";

type Props = {};
const ShadedList: FunctionComponent<Props> = ({ children }) => {
  return <ul className="ShadedList">{children}</ul>;
};

const ShadedListItem: FunctionComponent<Props> = ({ children }) => {
  return <li className="ShadedList__Item">{children}</li>;
};

export default ShadedList;
export { ShadedListItem };
