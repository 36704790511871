import React, { FunctionComponent } from "react";
import { mergeClassNames } from "../../helpers";
import MaybeClassProps from "../MaybeClassProps.type";

const ReferenceItem: FunctionComponent<MaybeClassProps> = ({
  children,
  className,
}) => {
  return (
    <li className={mergeClassNames("References__Item", className)}>
      {children}
    </li>
  );
};

export default ReferenceItem;
