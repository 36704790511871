import React, { FunctionComponent } from "react";
import { mergeClassNames } from "../../helpers";

import "./References.scss";
import MaybeClassProps from "../MaybeClassProps.type";
import Strong from "../Strong";

const References: FunctionComponent<MaybeClassProps> = ({
  children,
  className,
}) => {
  return (
    <div className={mergeClassNames("References text-sm pt-6", className)}>
      <Strong>References:</Strong>
      {children}
    </div>
  );
};

export default References;
